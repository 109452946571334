import React from "react";
import { Helmet } from "react-helmet";
import { graphql } from "gatsby";
import { makeStyles } from "@material-ui/core/styles";
import rehypeReact from "rehype-react";

import { Box, Typography, useMediaQuery, useTheme } from "@material-ui/core";
import cx from "clsx";
import Grid from "@material-ui/core/Grid";
import { Link } from "gatsby-theme-material-ui";
import { getSrc } from "gatsby-plugin-image";
import CardMedia from "@material-ui/core/CardMedia";

import Layout from "../components/Layout";
import Container from "../components/Container";
import useAdvert from "../hooks/useAdvert";

const renderAst = new rehypeReact({
  createElement: React.createElement,
}).Compiler;

const useStyles = makeStyles((theme) => ({
  root: {
    marginTop: "2em",
  },
  body: {
    textAlign: "justify",
  },
  action: {
    display: "flex",
    justifyContent: "center",
    maxWidth: "200px",
  },
  imageContainer: {
    position: "relative",
    height: "100%",
  },
  desktopImg: {
    width: "30%",
    float: "right",
    margin: "2em",
  },
  mobileImg: {
    display: "block",
    width: "100%",
    margin: "16px 0",
  },
  advert: {
    margin: "1em 0",
  },
  advertImg: {
    objectFit: "contain",
  },
  content: {},
}));

const BlogPage = ({ data: { markdownRemark: c } }) => {
  const styles = useStyles();
  const theme = useTheme();
  const advert = useAdvert();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  return (
    <Layout pageName={c.frontmatter.title}>
      <Helmet>
        <title>{c.frontmatter.title}</title>
      </Helmet>

      <Container maxWidth="md" type="content">
        <Grid xs={12} className={cx(styles.advert)}>
          <Link to={"/grouptrips"}>
            <CardMedia
              className={cx(styles.advertImg)}
              image={isMobile ? advert.sm : advert.lg}
              component="img"
              alt="advertisement"
            />
          </Link>
        </Grid>

        <Box className={styles.content}>
          <Typography variant="h4" style={{ marginBottom: "2em" }}>
            {c.frontmatter.title}
          </Typography>

          <Typography variant="body2" style={{ marginBottom: "2em" }}>
            <div className={cx(styles.imageContainer)}>
              {isMobile
                ? (
                  <img
                    src={getSrc(c.frontmatter.image)}
                    alt={c.frontmatter.type}
                    className={cx(styles.mobileImg)}
                  />
                )
                : (
                  <img
                    src={getSrc(c.frontmatter.image)}
                    alt={c.frontmatter.type}
                    className={cx(styles.desktopImg)}
                  />
                )}
            </div>

            {renderAst(c.htmlAst)}
          </Typography>
        </Box>
      </Container>
    </Layout>
  );
};
export default BlogPage;

export const query = graphql`
    query($slug: String!) {
        markdownRemark(frontmatter: { slug: { eq: $slug } }) {
            htmlAst
            frontmatter {
                emailRecipient
                emailSubject
                image {
                    childImageSharp {
                        gatsbyImageData(
                            placeholder: BLURRED
                            formats: [AUTO, WEBP, AVIF]
                        )
                    }
                }
                overline
                title
                type
            }
        }
    }
`;
